import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Clock from 'react-live-clock';
import Sentry from "react-activity/dist/Sentry";
import "react-activity/dist/Sentry.css";


const ClockComponent = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (
    <Card variant="outlined" sx={{ width: 290, border: 'none' }} style={{ background: 'rgba(21,23,43,.73' }}>
      <CardContent>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div onClick={() => {
          }} className='live-indicator'><Sentry speed={.5} size={20} color="#fff" />
          </div>
          <div style={{ fontSize: 20, marginLeft: 10, fontWeight: 'bold', color: '#fff' }}>
            <Clock format={'HH:mm:ss'} ticking={true} timezone={timezone} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ClockComponent;
