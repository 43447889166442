import React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';

interface CardProps {
    title: string;
    titleContent: string;
    subtitle: string;
    subtitleContent: string;
    thirdTitle?: string;
    thirdTitleContent?: string;
}

const CardComponent: React.FC<CardProps> = ({ title, titleContent, subtitle, subtitleContent, thirdTitle, thirdTitleContent }) => {
    return (
        <Card variant="outlined" style={{ background: 'rgba(21,23,43,.73' }} sx={{ width: 250, border: 'none' }}>

            <CardContent  >

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <svg width="26" height="26" viewBox="0 0 64 64" transform="rotate(180)"><g fill="none" fill-rule="evenodd"><path d="M0 0H64V64H0z" transform="translate(-323 -2082) translate(207 2050) translate(116 32)"></path><g><path d="M0 0H64V64H0z" transform="translate(-323 -2082) translate(207 2050) translate(116 32)"></path><g><path fill="#CBF200" d="M42.667 53.333c0 2.934-2.4 5.334-5.334 5.334h-32C2.4 58.667 0 56.267 0 53.333v-48C0 2.4 2.4 0 5.333 0h32c2.934 0 5.334 2.4 5.334 5.333" transform="translate(-323 -2082) translate(207 2050) translate(116 32) translate(10.667 2.667)"></path><path fill="#004A59" d="M19.467 15.2c1.066-1.067 2.666-1.067 3.733 0l9.6 9.6c1.6 1.6.533 4.533-1.867 4.533h-5.6v13.334c0 2.133-1.866 4-4 4-2.133 0-4-1.867-4-4V29.333H12c-2.4 0-3.467-2.933-1.867-4.533l9.334-9.6zM42.667 5.333C42.667 2.4 40.267 0 37.333 0h-32C2.4 0 0 2.4 0 5.333V8h42.667M42.667 50.667H0v2.666c0 2.934 2.4 5.334 5.333 5.334h32c2.934 0 5.334-2.4 5.334-5.334" transform="translate(-323 -2082) translate(207 2050) translate(116 32) translate(10.667 2.667)"></path></g></g></g></svg>
                    <Typography level="h1" sx={{ fontSize: 40, color: '#cbf200' }}>{titleContent}</Typography>

                </div>
                <Typography level="title-lg" sx={{ fontSize: 16, color: '#cbf200' }}>{title}</Typography>
                <CardContent orientation="horizontal" >
                    <Typography
                        level="body-lg"
                        textColor="#fff"
                        sx={{ fontWeight: 'lg', fontSize: 22 }}
                    >
                        {subtitleContent}
                    </Typography>
                    <Divider orientation="vertical" />
                    <Typography
                        level="body-lg"
                        textColor="#fff"
                        sx={{ fontWeight: 'lg', fontSize: 16 }}
                    >
                        {subtitle}
                    </Typography>
                </CardContent>
                <CardContent orientation="horizontal" >
                    <Typography
                        level="body-lg"
                        textColor="#d900c7"
                        sx={{ fontWeight: 'lg', fontSize: 16 }}
                    >
                        {thirdTitleContent}
                    </Typography>
                    <Divider orientation="vertical" />
                    <Typography
                        level="body-lg"
                        textColor="#d900c7"
                        sx={{ fontWeight: 'lg', fontSize: 13 }}
                    >
                        {thirdTitle}
                    </Typography>
                </CardContent>
            </CardContent>

        </Card>
    );
};

export default CardComponent;